import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

/**
 * Component for killing all specified events at the parent level
 */
const InteractionKiller = (props) => {
  const { allowed, events } = props;

  const ref = useRef();
  useEffect(() => {
    const listener = (e) => {
      const isAllowed = !!Object.keys(allowed)
        .filter((selector) => allowed[selector].includes(e.type))
        .find((selector) => !e.target.closest || e.target.closest(selector));

      if (!isAllowed) {
        e.preventDefault();
        e.stopPropagation();
      }
    };

    const { current: el } = ref;
    if (el?.parentNode && events?.length) {
      events.forEach(event => el.parentNode.addEventListener(event, listener, true));
    }

    return () => el?.parentNode && events?.length
      && events.forEach((event) => el.parentNode.removeEventListener(event, listener));
  }, [allowed, events]);

  return (
    <span className="interaction-killer" ref={ref} />
  );
};

InteractionKiller.defaultProps = {
  allowed: {},
  events: ['click'],
};

InteractionKiller.propTypes = {
  allowed: PropTypes.object,
  events: PropTypes.arrayOf(PropTypes.string),
};

export default InteractionKiller;
