import { createSlice } from '@reduxjs/toolkit';
import URI from 'urijs';

import { followUser, unfollowUser } from 'ERC/following/slice';
import getExpMessage from 'ERC/localization/getExpMessage';

import { buildQueryUrl } from '../../utils/reactRouterUtils';
import { sendEvent } from '../../utils/analytics';

import {
  checkAndDismissDeniedStatus,
  CLEAR_FEATURED_PRODUCTS,
  DEFAULT_COMMUNITY_MEMBER_COUNT,
  deleteUgc,
  editUgc,
  FETCH_ACTIVITY_FEED,
  FETCH_BRAND_BANNERS,
  FETCH_BRAND_CONTENT,
  FETCH_CERTIFICATION,
  FETCH_COMMUNITIES,
  FETCH_PROFILE_NEXT_TUTORIAL,
  FETCH_RELATED_BRANDS,
  FETCH_UGC,
  FETCH_VOTES,
  fetchAffiliations,
  fetchBrandAwards,
  fetchCampaigns,
  fetchFeaturedProducts,
  fetchFollowedMembers,
  fetchHasReviewableProducts,
  fetchHeroUGC,
  fetchMembers,
  fetchMemberSummary,
  fetchNeighbors,
  fetchOrgAccessLevels,
  fetchPageTopics,
  fetchRecentBrandReviews,
  fetchGroupSearchResults,
  fetchTeaserBrands,
  fetchTeaserProducts,
  fetchTeaserTeams,
  fetchTopMembers,
  fetchTopSellers,
  LOAD_USER,
  postUgc,
  postUgcComment,
  REMOVE_SURVEY,
  removeUgcBrandTag,
  toggleUgcLike,
  updateActivityFeedItem,
  updateRecentBrandReviews,
  updateUserVotes,
} from './actions';
import { PageTypesByType } from '../PageTypes';

// These are URIs coming from the properties files
export const PROPERTY_XAPI_URIS = [
  'feedContentEndpoint',
  'knowledgeContentExternalURI',
  'organizationExternalURI',
  'organizationPublicURI',
  'profileActiveCategoriesURI',
];

/**
 * Build out the URL that will be used fetch all available feed content for this page
 * @private
 * @param config {object} The top level config
 * @param passedData {object} The data returned from community-xapi
 * @returns {string} The feed-content URL with all necessary query params
 */
const buildFeedContentURI = (config, passedData) => {
  const params = {
    campaignVersions: config.supportedCampaignVersions,
    communityMemberCount: DEFAULT_COMMUNITY_MEMBER_COUNT,
    includeUnconnected: true,
    types: config.supportedFeedItemTypes.split(','),
  };

  // Apply the pageType-specific parameters
  switch (passedData.pageType) {
    case 'MANUFACTURER':
      // Use the configured placement for brand pages instead
      params.placement = config.feedContentPlacement;
      break;
    case 'PROGRAM':
      // Remove the COMMUNITY feed item type if this is a PROGRAM page
      if (params.types.includes('COMMUNITY')) {
        params.types.splice(params.types.indexOf('COMMUNITY'), 1);
      }
      break;
    default:
  }

  // Add in the preview token if we are in preview mode
  if (config.previewConfig?.token) params.previewToken = config.previewConfig.token;

  return buildQueryUrl(passedData.campaignSearchURI || config.feedContentEndpoint, params);
};

/**
 * extractURIsFromConfig is used to pluck URIs from the top level config so it's
 * accessible.
 * @param config {object} The top level config.
 * @returns {object} URIs on the top level config.
 */
const extractURIsFromConfig = config => {
  const uriObj = {};

  PROPERTY_XAPI_URIS.forEach(name => {
    if (config[name]) {
      uriObj[name] = config[name];
    }
  });

  return uriObj;
};

const performItemUpdate = (state, targetId, getUpdates) => {
  const update = (item) => (!targetId || item.id === targetId ? ({
    ...item,
    ...getUpdates(item),
  }) : item);

  // Update the content in the loaded list of UGC content
  if (state.ugcContent?.length) {
    state.ugcContent = state.ugcContent.map(update);
  }
};

const defaultStateFunc = () => {
  const globalConfig = window.communityConfig || {};
  const passedData = {
    // eslint-disable-next-line no-underscore-dangle
    ...globalConfig.__INITIAL_STATE__?.communityPage || {},
  };

  let avatar = null;
  if (passedData?.images?.AVATAR?.uri) {
    avatar = passedData.images.AVATAR.uri;
  }

  // Default the member label based on page type
  if (!passedData.memberLabel) {
    if (passedData.pageType === PageTypesByType.RETAILER.type) {
      passedData.memberLabel = 'retail employees';
    } else {
      passedData.memberLabel = 'experts';
    }
  }

  // Translate the member label if we can
  passedData.memberLabel = getExpMessage({
    defaultMessage: passedData.memberLabel,
    messageKey: `community.member-label.${passedData.memberLabel}`,
  });

  // Prefer the override status & type if specified
  const {
    preview = false,
    previewConfig = {},
  } = globalConfig;

  if (preview && previewConfig) {
    passedData.membershipStatus = previewConfig.membershipStatus || passedData.membershipStatus;
    passedData.membershipType = previewConfig.membershipType || passedData.membershipType;
  }

  return {
    ...extractURIsFromConfig(globalConfig),
    ...passedData,
    acquisitionAward: null,
    acquisitionAwardLoaded: false,
    activityFeed: null,
    allCategories: null,
    avatar,
    banner: null,
    brandAffinities: null,
    brandContent: null,
    brandProductCount: null,
    campaigns: null,
    campaignSearchURI: buildFeedContentURI(globalConfig, passedData),
    campaignsError: false,
    campaignsLoaded: false,
    cloudinaryCloudName: globalConfig.cloudinaryCloudName,
    communityCards: null,
    communities: [],
    communitiesEndpoint: globalConfig.communitiesEndpoint,
    communityPageURI: URI(passedData.communityPageURI).path(), // convert to relative path
    cdn: globalConfig.cdn,
    enrichedProducts: null,
    followedMembers: {},
    getMoreActivity: true,
    groups: [],
    groupsFound: true,
    hasReviewableProducts: false,
    hero: '',
    heroUGC: null,
    heroUGCLoaded: false,
    iframeResizerUrl: globalConfig.iframeResizerUrl,
    includeApplicationCta: passedData.includeApplicationCta,
    includeCampaigns: passedData.includeCampaigns,
    includeFeaturedProducts: passedData.includeFeaturedProducts,
    includeUgc: passedData.includeUgc,
    isAuthenticated: !!globalConfig.userUuid,
    isBrandTagAdmin: globalConfig.isBrandTagAdmin,
    isMember: ['ACTIVE', 'EXPIRING_SOON'].includes(passedData.membershipStatus),
    isNTBP: false,
    maxDescription: 120,
    memberCount: null,
    members: {},
    mfgId: passedData.pageType === PageTypesByType.MANUFACTURER.type
      ? passedData.entityIdentifier : null,
    neighbors: {},
    orgAccessLevels: null,
    orgAccessLevelsLoaded: false,
    preview: !!globalConfig.preview,
    previewCampaign: globalConfig.previewCampaign || null,
    previewConfig,
    recentContentArticles: null,
    recentBrandReviews: null,
    showReviews: passedData.showReviews,
    staticAssetsPath: globalConfig.staticAssetsPath,
    teaserBrandsLoading: true,
    teaserBrands: null,
    teaserTeams: null,
    teaserTeamsLoading: true,
    topMembers: {},
    topSellers: null,
    tutorial: {},
    ugcContent: [],
    ugcPagination: {},
    user: {
      firstName: 'Name',
      lastName: 'Unknown',
      uuid: globalConfig.userUuid,
      avatar: '',
    },
    votes: null,
    uuid: globalConfig.userUuid,
    userId: globalConfig.userId,
  };
};

const slice = createSlice({
  name: 'community',
  initialState: defaultStateFunc(),
  reducers: {
    setPreviewConfig: (state, action) => {
      const { isAuthenticated, membershipStatus, membershipType } = action.payload;
      state.previewConfig = {
        ...state.previewConfig,
        ...action.payload,
      };

      if (isAuthenticated !== undefined) {
        state.isAuthenticated = !!isAuthenticated;
      }

      if (state.isAuthenticated) {
        if (membershipStatus) {
          state.isMember = ['ACTIVE', 'EXPIRING_SOON'].includes(membershipStatus);
          state.membershipStatus = membershipStatus;
        }
      } else {
        state.isMember = false;
        state.membershipStatus = 'NO_STATUS';
      }

      if (membershipType) {
        state.membershipType = membershipType;
      }
    },
  },
  extraReducers: {
    [checkAndDismissDeniedStatus.fulfilled]: (state, action) => {
      state.deniedStatusDismissal = action.payload;
    },

    [`${FETCH_ACTIVITY_FEED}_FULFILLED`]: (state, action) => {
      const { feedItemDtos, nextPage } = action.payload;

      state.activityFeed = (state.activityFeed || []).concat(feedItemDtos);
      state.activityNextPageFilters = nextPage;
      state.getMoreActivity = Object.values(nextPage?.itemTypesMaxTime || {}).some(val => !!val);
    },

    [fetchAffiliations.fulfilled]: (state, action) => {
      state.affiliationsLoaded = true;
      state.pendingTeam = action.payload?.find(({ status }) => status === 'PENDING');
    },

    [fetchBrandAwards.fulfilled]: (state, action) => {
      const awards = action.payload?.content || [];

      // pluck the first award from the list for acquisition pages
      // to avoid showing the same award twice
      if (action.meta?.acquisition) {
        const acquisitionAward = awards?.[0];

        if (awards.length > 1) {
          awards.shift();
        }

        state.acquisitionAward = acquisitionAward;
        state.acquisitionAwardLoaded = true;
      }

      state.awards = awards;
    },
    [fetchBrandAwards.rejected]: (state) => {
      state.awards = [];
      state.acquisitionAwardLoaded = true;
    },

    [`${FETCH_BRAND_BANNERS}_FULFILLED`]: (state, action) => {
      state.banner = (action.payload || [])[0] || {};
    },
    [`${FETCH_BRAND_CONTENT}_FULFILLED`]: (state, action) => {
      state.brandContent = action.payload.brandContent;
      state.recentContentArticles = action.payload.recentContentArticles;
    },
    [`${FETCH_BRAND_CONTENT}_REJECTED`]: (state, action) => {
      const { response = {} } = action.payload || {};
      state.brandContent = {};
      state.recentContentArticles = [];
      sendEvent('BRAND_BANNER_LOAD_FAILURE', {
        status: response.status,
        statusCode: response.statusCode,
      });
    },
    [fetchCampaigns.pending]: (state) => {
      state.campaignsError = false;
      state.campaignsLoaded = false;
    },
    [fetchCampaigns.fulfilled]: (state, action) => {
      const { campaigns = [] } = action.payload;
      state.campaigns = campaigns;
      state.campaignsError = false;
      state.campaignsLoaded = true;

      // Look at each campaign to determine if it has learn or shop content
      const learnCount = campaigns.filter(c => c.hasLearnLink).length;
      const shopCount = campaigns.filter(c => c.hasShopLink).length;

      // TODO: maybe just load from campaign-xapi /targetedContent/access endpoint?
      if (state.pageType.toUpperCase() === 'MANUFACTURER') {
        state.accessLevel = {
          hasAccess: !!learnCount || !!shopCount,
          hasLearn: !!learnCount,
          hasShop: !!shopCount,
        };
      }

      sendEvent('CAMPAIGN_CARDS_LOADED', {
        numberOfCards: campaigns.length,
        numberOfLearnLinks: learnCount,
      });
    },
    [fetchCampaigns.rejected]: (state, action) => {
      state.campaignsError = true;
      state.campaignsLoaded = false;

      window.Exp?.alerts?.error?.({
        content: getExpMessage({
          defaultMessage: 'Uh oh! There was a problem loading some of the content, please try again later.',
          messageKey: 'community.problemLoadingContent',
        }),
        id: 'community-error',
      });

      const { message, response = {} } = action.payload || {};
      sendEvent('CAMPAIGN_CARDS_LOAD_ERROR', {
        message,
        status: response.status,
        statusText: response.statusText,
      });
    },
    [`${FETCH_CERTIFICATION}_FULFILLED`]: (state, action) => {
      const {
        certifications = {},
        certifiable = false,
      } = action.payload.data || {};

      state.certifiedCampaigns = Object.values(certifications)
        .filter((cert) => cert.certified)
        .map((cert) => cert.campaignId);
      state.certifiable = certifiable;
    },
    [`${FETCH_COMMUNITIES}_FULFILLED`]: (state, action) => {
      state.communities = action.payload.data || [];
    },
    [`${CLEAR_FEATURED_PRODUCTS}_FULFILLED`]: (state) => {
      state.featuredProducts = [];
    },

    [fetchFeaturedProducts.fulfilled]: (state, action) => {
      const { resultItems = [], totalResults } = action.payload || {};
      state.featuredProducts = resultItems;
      state.brandProductCount = totalResults;
    },

    [followUser.fulfilled]: (state) => {
      state.followedMembers.stale = true;
    },
    [unfollowUser.fulfilled]: (state) => {
      state.followedMembers.stale = true;
    },

    [fetchFollowedMembers.pending]: (state) => {
      state.followedMembers.loading = true;
    },
    [fetchFollowedMembers.fulfilled]: (state, action) => {
      const data = action.payload || {};
      const { content = [], last = true, totalElements = null } = data;
      let { page, size } = (action.meta.arg || {});

      page = page || data.number || 0;
      size = size || data.size || 20;

      if (page === 0) {
        // Reset the list if we just loaded page 0
        state.followedMembers.list = [];
      }

      state.followedMembers.currentPage = page;
      state.followedMembers.hasMore = !last;
      state.followedMembers.list = (state.followedMembers.list || []).concat(content);
      state.followedMembers.loading = false;
      state.followedMembers.pageSize = size;
      state.followedMembers.totalCount = totalElements;
    },
    [fetchFollowedMembers.rejected]: (state) => {
      state.followedMembers.list = [];
      state.followedMembers.loading = false;
    },

    [fetchHasReviewableProducts.fulfilled]: (state, action) => {
      state.hasReviewableProducts = !!action.payload;
    },

    [fetchHeroUGC.fulfilled]: (state, action) => {
      state.heroUGC = action.payload;
      state.heroUGCLoaded = true;
    },

    [`${FETCH_PROFILE_NEXT_TUTORIAL}_FULFILLED`]: (state, action) => {
      state.tutorial.loading = false;
      state.tutorial.tasks = action.payload;
    },
    [`${FETCH_PROFILE_NEXT_TUTORIAL}_PENDING`]: (state) => {
      state.tutorial.loading = true;
    },
    [`${FETCH_PROFILE_NEXT_TUTORIAL}_REJECTED`]: (state) => {
      state.tutorial.loading = false;
      state.tutorial.tasks = [];
    },

    [fetchMembers.pending]: (state) => {
      state.members.loading = true;
    },
    [fetchMembers.fulfilled]: (state, action) => {
      const data = action.payload || {};
      const { content = [], last = true, totalElements = null } = data;
      let { page, size } = (action.meta.arg || {});

      page = page || data.number || 0;
      size = size || data.size || 20;

      if (page === 0) {
        // Reset the list if we just loaded page 0
        state.members.list = [];
      }

      state.members.currentPage = page;
      state.members.hasMore = !last;
      state.members.list = (state.members.list || []).concat(content);
      state.members.loading = false;
      state.members.pageSize = size;
      state.members.totalCount = totalElements;
    },
    [fetchMembers.rejected]: (state, action) => {
      const { message, response = {} } = action.payload || {};
      state.members.list = [];
      state.members.loading = false;
      sendEvent('COMMUNITY_MEMBERS_LOAD_ERROR', {
        message,
        status: response.status,
        statusText: response.statusText,
      });
    },

    [fetchMemberSummary.pending]: (state) => {
      state.memberSummaryLoading = true;
    },
    [fetchMemberSummary.fulfilled]: (state, action) => {
      const { totalElements = null } = action.payload || {};
      state.memberCount = totalElements;
      state.memberSummaryLoading = false;
    },

    [fetchMemberSummary.rejected]: (state, action) => {
      const { message, response = {} } = action.payload || {};
      state.memberSummaryLoading = false;
      sendEvent('COMMUNITY_MEMBER_SUMMARY_LOAD_ERROR', {
        message,
        status: response.status,
        statusText: response.statusText,
      });
    },

    [fetchNeighbors.pending]: (state) => {
      state.neighbors.loading = true;
    },
    [fetchNeighbors.fulfilled]: (state, action) => {
      const { content = [], totalElements = null } = action.payload || {};

      state.neighbors.hasLoaded = true;
      state.neighbors.list = [...content];
      state.neighbors.loading = false;
      state.neighbors.totalCount = totalElements;
    },
    [fetchNeighbors.rejected]: (state) => {
      state.neighbors.list = [];
      state.neighbors.loading = false;
    },

    [fetchOrgAccessLevels.fulfilled]: (state, action) => {
      state.orgAccessLevels = action.payload.orgAccessLevels;
      state.orgAccessLevelsLoaded = true;
    },

    [fetchPageTopics.fulfilled]: (state, action) => {
      state.topics = action.payload;
    },

    [fetchRecentBrandReviews.fulfilled]: (state, action) => {
      state.recentBrandReviews = action.payload?.results || [];
    },

    [fetchRecentBrandReviews.pending]: (state, action) => {
      state.recentBrandReviews = action.payload?.results || [];
    },

    [fetchTeaserBrands.pending]: (state) => {
      state.teaserBrandsLoading = true;
    },
    [fetchTeaserBrands.fulfilled]: (state, action) => {
      state.teaserBrands = action.payload;
      state.teaserBrandsLoading = false;
    },

    [fetchTeaserProducts.fulfilled]: (state, action) => {
      state.enrichedProducts = action.payload;
    },

    [fetchGroupSearchResults.fulfilled]: (state, action) => {
      state.groupsFound = action.payload.content?.length > 0;
      state.groups = action.payload.content;
    },
    [fetchTeaserTeams.pending]: (state) => {
      state.teaserTeamsLoading = true;
    },
    [fetchTeaserTeams.fulfilled]: (state, action) => {
      state.teaserTeams = action.payload;
      state.teaserTeamsLoading = false;
    },

    [fetchTopMembers.pending]: (state) => {
      state.topMembers.loading = true;
    },
    [fetchTopMembers.fulfilled]: (state, action) => {
      const data = action.payload || {};
      const { content = [], last = true, totalElements = null } = data;
      let { page, size } = (action.meta.arg || {});

      page = page || data.number || 0;
      size = size || data.size || 20;

      if (page === 0) {
        // Reset the list if we just loaded page 0
        state.topMembers.list = [];
      }

      state.topMembers.currentPage = page;
      state.topMembers.hasMore = !last;
      state.topMembers.list = (state.topMembers.list || []).concat(content);
      state.topMembers.loading = false;
      state.topMembers.pageSize = size;
      state.topMembers.totalCount = totalElements;
    },
    [fetchTopMembers.rejected]: (state, action) => {
      const { message, response = {} } = action.payload || {};
      state.topMembers.list = [];
      state.topMembers.loading = false;
      sendEvent('COMMUNITY_MEMBERS_LOAD_ERROR', {
        message,
        status: response.status,
        statusText: response.statusText,
      });
    },

    [fetchTopSellers.fulfilled]: (state, action) => {
      state.topSellers = action.payload?.resultItems;
    },

    [`${FETCH_RELATED_BRANDS}_FULFILLED`]: (state, action) => {
      state.brandAffinities = action.payload.brandAffinities;
    },
    [`${FETCH_UGC}_PENDING`]: (state) => {
      state.ugcContentLoading = true;
    },
    [`${FETCH_UGC}_FULFILLED`]: (state, action) => {
      const { payload: { data: content = {} }, meta: { size: pageSize } } = action;

      state.ugcContentLoading = false;
      state.ugcContent = state.ugcContent || [];

      if (content.results?.length) {
        state.ugcContent = state.ugcContent.concat(content.results);
        state.ugcContentEnd = content.totalPossibleResults < pageSize;
      } else {
        state.ugcContentEnd = true;
      }

      // Update the pagination to have the earliest creationDate for the next load's offset
      state.ugcPagination.creationDate = state.ugcContent
        .reduce((createdDate, item) => (createdDate && createdDate < item.creationDate
          ? createdDate : item.creationDate), null);
    },
    [`${FETCH_UGC}_REJECTED`]: (state) => {
      state.ugcContentEnd = true;
      state.ugcContentLoading = false;
    },
    [`${LOAD_USER}_FULFILLED`]: (state, action) => {
      const { payload: user } = action;
      if (user.firstName) state.user.firstName = user.firstName;
      if (user.lastName) state.user.lastName = user.lastName;
      if (user.avatar) state.user.avatar = user.avatar;
    },

    [deleteUgc]: (state, action) => {
      const id = action.payload;
      state.ugcContent = state.ugcContent.filter(post => post.id !== id);
    },
    [editUgc]: (state, action) => {
      const { id } = action.payload;
      performItemUpdate(state, id, () => action.payload);
    },
    [postUgc]: (state, action) => {
      state.ugcContent.unshift(action.payload);
    },
    [postUgcComment]: (state, action) => {
      const { comment, id } = action.payload;
      performItemUpdate(state, id, (item) => ({
        commentCount: (item.commentCount || 0) + 1,
        comments: (item.comments || []).concat([comment]),
      }));
    },
    [removeUgcBrandTag]: (state, action) => {
      const { id } = action.payload;
      state.ugcContent = state.ugcContent.filter(post => post.id !== id);
    },
    [toggleUgcLike]: (state, action) => {
      const { id, like, liked } = action.payload;
      performItemUpdate(state, id, (item) => ({
        likeCount: (item.likeCount || 0) + (liked ? 1 : -1),
        likes: (item.likes || [])
          .filter(({ liker }) => liker?.uuid !== state.uuid)
          .concat(like && liked ? [like] : []),
      }));
    },

    [`${REMOVE_SURVEY}`]: (state, action) => {
      const { id } = action.payload;
      state.campaigns = state.campaigns.filter((card) => {
        const { type } = card;
        return type !== 'SURVEY' || (card.id !== id && type === 'SURVEY');
      });
    },

    [`${FETCH_VOTES}_FULFILLED`]: (state, action) => {
      state.votes = action.payload;
    },

    [updateActivityFeedItem]: (state, action) => {
      const { payload: socialism } = action;
      const { id } = socialism;

      state.activityFeed = state.activityFeed?.map(feedItem => ({
        ...feedItem,
        item: feedItem.item.id === id ? socialism : feedItem.item,
      }));
    },

    [updateRecentBrandReviews]: (state, action) => {
      state.recentBrandReviews = action.payload;
    },

    [updateUserVotes]: (state, action) => {
      const { payload: { added = [], removed = [] } } = action;

      state.votes = state
        .votes
        .filter(vote => !removed.some(rv => rv.voteItemId === vote.voteItemId))
        .concat(added);
    },
  },
});

export const { setPreviewConfig } = slice.actions;

export default slice.reducer;
