import React, { Suspense } from 'react';
import { BrowserRouter, Redirect, Route, Switch, withRouter } from 'react-router-dom';
import { Provider } from 'react-redux';

import { CHILD_COMMUNITY_PATH, CONTENT_PAGE_PATH, STANDARD_PAGE_PATH, EXTERNAL_ROUTE_PATHS } from './routes';
import createStore from './redux/store';
import CommunityPage from './communityPage';
import ErrorBoundary from '../common/ErrorBoundary';

const ContentPage = React.lazy(() => import(/* webpackChunkName: "community-contentPage" */ './contentPage'));
const Error = React.lazy(() => import(/* webpackChunkName: "community-error" */ './components/error'));

const ContentPageRoute = (props) => (
  <Suspense fallback={<div />}>
    <ContentPage {...props} />
  </Suspense>
);

const ErrorRoute = (props) => (
  <Suspense fallback={<div />}>
    <Error {...props} />
  </Suspense>
);

const ExternalRoute = () => {
  window.location.reload();

  return null;
};

const ScrollToTopOnNewPage = withRouter(({ children, history: { action } }) => {
  // reset the scroll if we navigate somewhere new and the user hasn't hit forward/back
  if (action === 'PUSH') {
    window.scrollTo(0, 0);
  }

  return children;
});

const store = createStore();

const Router = () => (
  <ErrorBoundary visible>
    <BrowserRouter>
      <Provider store={store}>
        <ScrollToTopOnNewPage>
          <Switch>
            {/* trim any trailing slashes */}
            <Redirect from="*(/+)" to="*" />

            {/* content page routes */}
            <Route path={`${CONTENT_PAGE_PATH}/topic/:topicId`} component={ContentPageRoute} />
            <Redirect from={`${CONTENT_PAGE_PATH}/topic`} to={CONTENT_PAGE_PATH} />
            <Route path={`${CONTENT_PAGE_PATH}/:articleId`} component={ContentPageRoute} />
            <Route path={CONTENT_PAGE_PATH} component={ContentPageRoute} />

            {/* community page routes */}
            <Route path={CHILD_COMMUNITY_PATH} component={CommunityPage} />
            <Route path={STANDARD_PAGE_PATH} component={CommunityPage} />

            {/* external route redirect */}
            <Route path={EXTERNAL_ROUTE_PATHS} component={ExternalRoute} />

            {/* this can be hit if react-router routes to a non-community url after loading */}
            <Route component={ErrorRoute} />
          </Switch>
        </ScrollToTopOnNewPage>
      </Provider>
    </BrowserRouter>
  </ErrorBoundary>
);

export default Router;
