import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import PreviewBar from 'ERC/previewMode/PreviewBar';
import { setPreviewConfig } from '../../redux/communityReducer';

import { buildUriQueryString, useQueryParams } from '../../../utils/reactRouterUtils';

import './preview.less';

/**
 * Community Preview Component
 *
 * @author jon.morris
 */
const CommunityPreview = () => {
  const dispatch = useDispatch();
  const isAuthenticated = useSelector((state) => state.community.isAuthenticated);
  const membershipStatus = useSelector((state) => state.community.membershipStatus);
  const membershipType = useSelector((state) => state.community.membershipType);
  const userId = useSelector((state) => state.community.userId);

  const queryParams = useQueryParams();
  const params = useMemo(() => {
    const [qia] = queryParams.isAuthenticated || [];
    const [qms] = queryParams.membershipStatus || [];
    const [qmt] = queryParams.membershipType || [];
    return {
      isAuthenticated: qia === undefined ? undefined : qia === 'true',
      membershipStatus: qms,
      membershipType: qmt,
    };
  }, [queryParams]);

  const history = useHistory();
  const handleInputChange = (name) => (e) => {
    const { checked, value } = e.target;
    history.replace({
      search: buildUriQueryString({
        isAuthenticated: params.isAuthenticated === undefined ? undefined : params.isAuthenticated,
        membershipStatus: params.membershipStatus || membershipStatus,
        membershipType: params.membershipType || membershipType,
        [name]: checked === undefined ? value : checked,
      }),
    });
  };

  useEffect(() => {
    dispatch(setPreviewConfig(params));
  }, [dispatch, params]);

  return (
    <PreviewBar>
      <div className="preview-form">
        {userId ? (
          <div className="preview-form-column">
            <div className="preview-form-field preview-isAuthenticated-field">
              <label>Authenticated</label>
              <span className="switch-toggle switch-toggle-small">
                <input
                  checked={isAuthenticated}
                  id="isAuthenticated"
                  name="isAuthenticated"
                  onChange={handleInputChange('isAuthenticated')}
                  type="checkbox"
                />
                <label htmlFor="isAuthenticated">Authenticated</label>
              </span>
            </div>
          </div>
        ) : null}

        {membershipType !== 'NOT_APPLICABLE' ? (
          <div className="preview-form-column">
            {membershipStatus !== 'ACTIVE' || !isAuthenticated ? (
              <div className="preview-form-field preview-membershipType-field">
                <label htmlFor="membershipType">Applications</label>
                <select
                  name="membershipType"
                  onChange={handleInputChange('membershipType')}
                  value={membershipType}
                >
                  <option value="RESTRICTED">Accepted</option>
                  <option value="CLOSED">Closed</option>
                </select>
              </div>
            ) : null}

            {isAuthenticated ? (
              <div className="preview-form-field preview-membershipStatus-field">
                <label htmlFor="membershmembershipStatusipType">Membership Status</label>
                <select
                  name="membershipStatus"
                  onChange={handleInputChange('membershipStatus')}
                  value={membershipStatus}
                >
                  <option value="NO_STATUS">Visitor</option>
                  <option value="ACTIVE">Active Member</option>
                  <option value="EXPIRED">Former Member</option>
                  <option value="PENDING">Application Pending</option>
                  <option value="DENIED">Application Denied</option>
                  <option value="APPLICATION_EXPIRED">Application Expired</option>
                </select>
              </div>
            ) : null}
          </div>
        ) : null}
      </div>
    </PreviewBar>
  );
};

export default React.memo(CommunityPreview);
